<template>
  <div class="task-box" :class="locale === 'en' ? 'localeEn' : 'localeZh'">
    <h2>{{ $t("DesignTask") }}</h2>
    <div v-if="locale !== 'zh'" class="introduce-box">
      <div class="introduce-title">
        <div class="title4">12TH SAIC DESIGN CHALLENGE</div>
        <div class="title5">Electrifying Progress</div>
      </div>
      <a-button
        class="button-type5 button"
        style="font-size: 16px"
        @click="hDownload"
        type="primary"
        :loading="data.isLoading"
        >{{ data.isLoading ? $t("Downloading") : $t("Download") }}</a-button
      >
    </div>
    <div v-if="locale !== 'en'" class="introduce-video-box">
      <video-pc :videoSrc="props.videoSrc" />
    </div>
    <div v-if="locale !== 'en'" class="introduce-box">
      <a-button
        class="button-type5 button"
        style="font-size: 16px"
        @click="hDownload"
        type="primary"
        :loading="data.isLoading"
        >{{ data.isLoading ? $t("Downloading") : $t("Download") }}</a-button
      >
      <div class="introduce-title">
        <div class="title1">真实世界·多元共生</div>
        <div class="title2">REAL WORLD·DIVERSE SYMBIOSIS</div>
        <div class="title3">主赛道:汽车造型设计</div>
      </div>
    </div>

    <div class="advertising-img">
      <img :src="data.backgroundImage1" alt="" />
    </div>

    <div v-if="locale === 'zh'" class="introduce-title1">
      <div class="title1">作品提交内容及要求</div>
      <div class="title3">Submission Content and Requirements for Works</div>
    </div>

    <div v-if="locale === 'zh'" class="submission-content">
      <!-- <h4>提交材料：</h4> -->
      <div class="item-box lineHeight">
        <div>
          <span class="item-bold">1、车型平台限定： </span> <br />
          提交的系列作品需基于车身长度不得小于 3800mm，不得大于 5000mm。
        </div>
      </div>
      <div class="item-box lineHeight">
        <div>
          <span class="item-bold">2、个人介绍：</span><br />
          100 秒自我介绍 MP4 视频*1，简历 pdf*1。
          *如有作品集可附带提交（不做强制要求）
        </div>
      </div>
      <div class="item-box lineHeight">
        <div>
          <span class="item-bold">3、展示板面两张：</span><br />
          A3 幅面大小[420× 297mm]、JPEG 格式且分辨率不低于
          300DPI，排版形式横向排版；
        </div>
      </div>
      <div class="item-box lineHeight">
        <div>
          <span class="item-bold">内容中必须包含：</span><br />
          内容中必须包含： 草图展示，方案前后 45 度 3D
          渲染效果图各一张，正视图及侧视图的车身布局(Package)一张。<br />
          *设计作品需体现品牌（品牌选择：荣威，荣威飞凡，名爵）
        </div>
      </div>
      <div class="item-box lineHeight">
        <div>
          <span class="item-bold">作品尺寸需满足以下条件：</span><br />

          作品尺寸需满足以下条件： 车身长度不得小于 3800mm，不得大于 5000mm。<br />
          *展示板还可以展示：
          设计理念诠释、车身布局、概念草图、人机工程、细节设计等内容。（不做强制要求）
        </div>
      </div>
      <div class="item-box lineHeight">
        <div>
          <span class="item-bold">4、3D 数字模型文件：</span><br />
          可接受的软件格式包括但不限于：Alias、Pro-E、UG、Catia、 Maya、 3D
          Max、 Rhino、Blender。
        </div>
      </div>
      <!-- <img
        class="car-img"
        :src="data.backgroundImage2"
        alt=""
        @click="showFn(data.backgroundImage2)"
      />
      <div class="item-box">
        <p>
          <span class="item-bold">3、设计作品的3D数字模型文件：</span
          >可接受的软件格式包括但不限于：Alias、Pro-E、UG、Catia、 Maya、 3D
          Max、 Rhino、Blender。
        </p>
      </div>
      <h4>选交材料：</h4>
      <div class="item-box">
        <p><span class="item-bold">1、个人作品集</span></p>
      </div>
      <div class="item-box">
        <p>
          <span class="item-bold">2、更多展示板：</span
          >设计意象图、车身布局、概念草图、人机工程、细节设计、文字说明等内容。
        </p>
      </div> -->
    </div>

    <div v-if="locale === 'en'" class="submission-content">
      <h4>Submit contents:</h4>
      <div class="item-box">
        <p>
          <span class="item-bold">1-Intention:</span> Brief explanation story.
        </p>
      </div>
      <div class="item-box">
        <p><span class="item-bold">2-Moodboard:</span> supporting the story.</p>
      </div>
      <div class="item-box">
        <p>
          <span class="item-bold">3-3 views of the car:</span> Hand sketches +
          renders.
        </p>
      </div>
      <div class="item-box">
        <p><span class="item-bold">4-Quality requested:</span> A3, 72dpi.</p>
      </div>
      <div class="item-box">
        <p><span class="item-bold">5-New design,</span> never seen before.</p>
      </div>
      <div class="item-box">
        <p>
          *The dimensions must meet the following criteria: wheelbase no less
          than 2600mm and no greater than 3000mm; body length no less than
          3800mm and no greater than 5000mm.
        </p>
      </div>
      <img
        class="car-img"
        :src="data.backgroundImage2"
        alt=""
        @click="showFn(data.backgroundImage2)"
      />
      <p class="item-liner">Sub-track</p>
    </div>
    <div class="submission-content">
      <div v-if="locale === 'en'" class="introduce-title1">
        <div class="title4">Brand Strategy and Service Design Sub-track</div>
        <div class="title5">Cities and Cars</div>
      </div>
      <div v-if="locale === 'zh'" class="introduce-title1">
        <div class="title1">评审维度</div>
        <div class="title3">Evaluation Dimensions</div>
        <!-- <div class="title3">分赛道1:品牌战略与服务设计</div> -->
      </div>
      <div class="evaluationBox">
        <div>
          <span class="item-bold">· 用户穿透力（30%） </span
          >场景真实性，洞察颗粒度
        </div>
        <div>
          <span class="item-bold">· 品牌进化力（30%）</span>
          基因传承度，创新突破性
        </div>
        <div>
          <span class="item-bold">· 阵列协同力（20%）</span>
          谱系辨识度，品类互补性
        </div>
        <div>
          <span class="item-bold">· 设计表现力（20%）</span>
          风格原创度，表现感染力
        </div>
      </div>

      <!-- <p class="submission-title">{{ $t("CompetitionText99") }}</p>
      <h4>{{ $t("CompetitionText100") }}</h4>
      <div class="ul-item-box">
        <p>{{ $t("CompetitionText101") }}</p>
      </div>
      <h4>{{ $t("CompetitionText102") }}</h4>
      <div class="ul-item-box">
        <p>
          <span class="item-bold">{{ $t("CompetitionText103") }}</span
          ><span>{{ $t("CompetitionText104") }}</span>
        </p>
        <p class="item-bold">{{ $t("CompetitionText105") }}</p>
        <p class="item-bold">{{ $t("CompetitionText106") }}</p>
        <p class="item-bold">{{ $t("CompetitionText107") }}</p>
        <p class="item-bold">{{ $t("CompetitionText108") }}</p>
        <p class="item-bold">{{ $t("CompetitionText109") }}</p>
      </div>
      <h4>{{ $t("CompetitionText110") }}</h4>
      <div class="ul-item-box">
        <p>{{ $t("CompetitionText111") }}</p>
      </div> -->
    </div>

    <div class="submission-content">
      <div v-if="locale === 'en'" class="introduce-title2">
        <div class="title4">Sub-track: Car digital visualization design</div>
        <div class="title5">Hyper Imagination</div>
      </div>
      <div v-if="locale === 'zh'" class="introduce-title2">
        <div class="title1">版权申明</div>
        <div class="title3">Copyright Statement</div>
      </div>
      <div class="articleText lineHeight">
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;您需确保您的作品为原创作品，且未被授权给任何组织或个人商用（不论有偿或无偿）。本次比赛不接受在其他比赛中投稿的作品参赛。
        </p>
        <br />
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;主办方对在本次大赛中所提供的所有参赛作品均享有合法使用权益，参赛者一旦参赛，则视为同意并授权主办方及其关联公司自投稿之日起至大赛结束以后无偿地在全球范围内将其投稿作品用于活动和产品、品牌宣传，包括但不限于将作品用于全媒体平台、艺术展会，零售店以及路演等营销活动，据此使用参赛作品时不需要再另行通知参赛者或征得参赛者的同意。主办方及其关联公司有权基于以上目的授权合作方使用投稿作品。
        </p>
        <br />
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;获得全球一等奖，二等奖，三等奖，优秀奖，新星奖及最佳创意奖的参赛作品的版权（包括但不限于复制权、发行权、出租权、展览权等权利）转让给主办方，归主办方所有。
        </p>
      </div>

      <!-- <p class="submission-title">{{ $t("CompetitionText112") }}</p>
      <h4>{{ $t("CompetitionText113") }}</h4>
      <div class="ul-item-box">
        <p>{{ $t("CompetitionText114") }}</p>
      </div>
      <h4>{{ $t("CompetitionText115") }}</h4>
      <div class="ul-item-box">
        <p v-if="locale === 'en'">Entry submission</p>
        <p>
          <span class="item-bold">{{ $t("CompetitionText116") }}</span>
        </p>
        <p>
          <span class="item-bold">{{ $t("CompetitionText117") }}</span
          ><span>{{ $t("CompetitionText118") }}</span>
        </p>
        <p>
          <span class="item-bold">{{ $t("CompetitionText119") }}</span
          ><span>{{ $t("CompetitionText120") }}</span>
        </p>
        <p>
          <span class="item-bold">{{ $t("CompetitionText121") }}</span
          ><span>{{ $t("CompetitionText122") }}</span>
        </p>
      </div>
      <h4>{{ $t("CompetitionText123") }}</h4>
      <div class="ul-item-box">
        <p>
          <span class="item-bold">{{ $t("CompetitionText124") }}</span
          ><span>{{ $t("CompetitionText125") }}</span>
        </p>
        <p>
          <span class="item-bold">{{ $t("CompetitionText126") }}</span
          ><span>{{ $t("CompetitionText127") }}</span>
        </p>
        <p>
          <span class="item-bold">{{ $t("CompetitionText128") }}</span
          ><span>{{ $t("CompetitionText129") }}</span>
        </p>
        <p>
          <span class="item-bold">{{ $t("CompetitionText130") }}</span
          ><span>{{ $t("CompetitionText131") }}</span>
        </p>
      </div>
      <h4>{{ $t("CompetitionText132") }}</h4>
      <div class="ul-item-box">
        <p>
          <span class="item-bold">{{ $t("CompetitionText133") }}</span
          ><span>{{ $t("CompetitionText134") }}</span>
        </p>
        <p>
          <span class="item-bold">{{ $t("CompetitionText135") }}</span
          ><span>{{ $t("CompetitionText136") }}</span>
        </p>
        <p>
          <span class="item-bold">{{ $t("CompetitionText137") }}</span
          ><span>{{ $t("CompetitionText138") }}</span>
        </p>
        <p>
          <span class="item-bold">{{ $t("CompetitionText139") }}</span
          ><span>{{ $t("CompetitionText140") }}</span>
        </p>
      </div> -->
    </div>

    <div>
      <div v-if="locale === 'en'" class="introduce-title3">
        <div class="title4">Design Strategy and Communication Sub-track</div>
        <div class="title5">Needs and Minds</div>
      </div>
      <div v-if="locale === 'zh'" class="introduce-title3">
        <div class="title1">分赛道1:创新融合赛道</div>
        <!-- <div class="title2">Needs and minds</div> -->
        <div class="title3">空间再定义</div>
        <div class="title3">Space in definition</div>
      </div>
      <div class="advertising-img">
        <img :src="data.backgroundImage5" alt="" />
      </div>
    </div>

    <div class="submission-content">
      <div v-if="locale === 'en'" class="introduce-title4">
        <div class="title4">HMI Interaction Design Sub-track</div>
        <div class="title5">A 「WOW」smart cabin</div>
      </div>
      <div v-if="locale === 'zh'" class="introduce-title4">
        <div class="title1">作品提交内容及要求</div>
        <!-- <div class="title2">A 「WOW」smart cabin</div> -->
        <div class="title3">Submission Content and Requirements for Works</div>
      </div>
      <!-- <p class="submission-title">{{ $t("CompetitionText155") }}</p>
      <h4>{{ $t("CompetitionText156") }}</h4>
      <div class="ul-item-box">
        <p>{{ $t("CompetitionText157") }}</p>
      </div>
      <h4>{{ $t("CompetitionText158") }}</h4> -->
      <div class="ul-item-box lineHeight">
        <!-- <p>
          <span class="item-bold">{{ $t("CompetitionText159") }}</span>
          <br />
          <span>{{ $t("CompetitionText160") }}</span>
        </p>
        <p>
          <span class="item-bold">{{ $t("CompetitionText161") }}</span>
          <br /><span>{{ $t("CompetitionText162") }}</span>
        </p>
        <p>
          <span class="item-bold">{{ $t("CompetitionText163") }}</span>
          <br /><span>{{ $t("CompetitionText164") }}</span>
        </p>
        <p>
          <span class="item-bold">{{ $t("CompetitionText165") }}</span>
          <br /><span>{{ $t("CompetitionText166") }}</span>
        </p>
        <p>
          <span class="item-bold">{{ $t("CompetitionText167") }}</span>
          <br /><span>{{ $t("CompetitionText168") }}</span>
        </p> -->
        <p>
          <span class="item-bold">1、（小组）自我介绍：</span>
          <br />
          <span
            >100 秒自我介绍 MP4 视频*1，简历每个成员各一份 pdf*1。
            *如有作品集可附带提交（不做强制要求）。</span
          >
        </p>
        <p>
          <span class="item-bold">2、PPT</span>
          <br /><span>需包含设计背景、设计内容、设计解析及成果展现。</span>
        </p>
        <p>
          <span class="item-bold">3、展板：</span>
          <br /><span>需包含设计背景、设计意图、设计解析及成果展现。</span>
        </p>
        <p>
          <span class="item-bold"> 4、视频：</span>
          <br /><span
            >需清晰体现设计理念、设计语境及设计目标。不超过 150 秒，不大于
            2G。</span
          >
        </p>
        <p>
          <span class="item-bold">5、原型：</span>
          <br /><span>可互动体验 Booth，形式不限。</span>
        </p>
      </div>
    </div>
    <div class="submission-content">
      <div v-if="locale === 'en'" class="introduce-title5">
        <div class="title4">CMF Sub-track</div>
        <div class="title5">Beyond Colors</div>
      </div>
      <div v-if="locale === 'zh'" class="introduce-title5">
        <div class="title1">评审维度</div>
        <!-- <div class="title2">Beyond Colors</div> -->
        <div class="title3">Evaluation Dimensions</div>
      </div>
      <div class="evaluationBox">
        <div>
          <span class="item-bold">· 用户穿透力（25%） </span
          >场景真实性，洞察颗粒度
        </div>
        <div>
          <span class="item-bold">· 创新进化力（25%）</span>
          设计思维力，创新突破性
        </div>
        <div>
          <span class="item-bold">· 超级产品力（25%）</span>
          用户共鸣感，落地发展性
        </div>
        <div>
          <span class="item-bold">· 设计表现力（25%）</span>
          风格原创度，表现感染力
        </div>
      </div>
    </div>
    <div>
      <div v-if="locale === 'en'" class="introduce-title3">
        <div class="title4">Design Strategy and Communication Sub-track</div>
        <div class="title5">Needs and Minds</div>
      </div>
      <div v-if="locale === 'zh'" class="introduce-title3">
        <div class="title1">分赛道2:产品与设计战略分赛道</div>
        <!-- <div class="title2">Needs and minds</div> -->
        <div class="title3">下一个主流</div>
        <div class="title3">The Next Mainstream</div>
      </div>
      <div class="advertising-img">
        <img :src="data.backgroundImage6" alt="" />
      </div>
    </div>
    <div>
      <div v-if="locale === 'zh'" class="introduce-title3">
        <div class="title1">分赛道3:CMF分赛道</div>
        <!-- <div class="title2">Needs and minds</div> -->
        <div class="title3">共生印记--CMF设计的“网红#Tags”养成计划</div>
        <div class="title3">Symbiotic Imprint</div>
      </div>
      <div class="advertising-img">
        <img :src="data.backgroundImage7" alt="" />
      </div>
    </div>
    <div class="submission-content">
      <div v-if="locale === 'en'" class="introduce-title2">
        <div class="title4">Sub-track: Car digital visualization design</div>
        <div class="title5">Hyper Imagination</div>
      </div>
      <div v-if="locale === 'zh'" class="introduce-title2">
        <div class="title1">版权申明</div>
        <div class="title3">Copyright Statement</div>
      </div>
      <div class="lineHeight">
        <p>
          &nbsp;&nbsp; &nbsp;&nbsp;
          &nbsp;&nbsp;您需确保您的作品为原创作品，且未被授权给任何组织或个人商用（不论有偿或无偿）。本次比赛不接受在其他比赛中投稿的作品参赛。
        </p>
        <br />
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;主办方对在本次大赛中所提供的所有参赛作品均享有合法使用权益，参赛者一旦参赛，则视为同意并授权主办方及其关联公司自投稿之日起至大赛结束以后无偿地在全球范围内将其投稿作品用于活动和产品、品牌宣传，包括但不限于将作品用于全媒体平台、艺术展会，零售店以及路演等营销活动，据此使用参赛作品时不需要再另行通知参赛者或征得参赛者的同意。主办方及其关联公司有权基于以上目的授权合作方使用投稿作品。
        </p>
        <br />
        <p>
          &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
          获得全球一等奖，二等奖，三等奖，优秀奖，新星奖及最佳创意奖的参赛作品的版权（包括但不限于复制权、发行权、出租权、展览权等权利）转让给主办方，归主办方所有。
        </p>
      </div>
    </div>
    <div>
      <div v-if="locale === 'en'" class="introduce-title6">
        <div class="title4">Expert Jury</div>
        <div class="title5">专家评审团</div>
        <div class="title6">*Sort by the first letter of the surname</div>
      </div>
      <div v-if="locale === 'zh'" class="introduce-title6">
        <div class="title1">专家评审团</div>
        <div class="title3">Expert Jury</div>
        <div class="title6">*按姓氏首字母排列</div>
      </div>
      <div class="advertising-img">
  <img style="width: 1250px" :src="data.backgroundImage3" alt="" />
      </div>
    
    </div>
    <div class="TimeArrangement">
      <div v-if="locale === 'en'" class="introduce-title7">
        <div class="title4">Time Arrangement (Provisional)</div>
        <div class="title5">赛程时间（暂定）</div>
      </div>
      <div v-if="locale === 'zh'" class="introduce-title7">
        <div class="title1">赛程时间（暂定）</div>
        <div class="title2">Time Arrangement (Provisional)</div>
      </div>
       <div class="advertising-img">
      <img style="width: 1080px" :src="data.backgroundImage4" alt="" />
       </div>
    </div>
    <div class="submission-content">
      <div v-if="locale === 'en'" class="introduce-title8">
        <div class="title4">Submission Approach</div>
        <div class="title5">大赛作品提交方式</div>
      </div>
      <div v-if="locale === 'zh'" class="introduce-title8">
        <div class="title1">大赛作品提交方式</div>
        <div class="title3">Submission Approach</div>
      </div>
      <div class="submission-title ul-item-box">
        <p>
          <span >{{ $t("CompetitionText181") }}</span
          ><span>{{ $t("CompetitionText182") }}</span>
        </p>
        <p>
          <span >{{ $t("CompetitionText183") }}</span>
        </p>
        <p>
          <span >{{ $t("CompetitionText184") }}</span
          ><span>{{ $t("CompetitionText185") }}</span>
        </p>
        <p>
          <span >{{ $t("CompetitionText186") }}</span>
        </p>
        <p>
          <span >{{ $t("CompetitionText187") }}</span
          ><span>{{ $t("CompetitionText188") }}</span>
        </p>
      </div>
      <p v-if="locale === 'en'" class="submission-title">
        Mail：saicdesign@saicmotor.com
      </p>
    </div>
    <a-image
      v-if="data.current"
      :style="{ display: 'none' }"
      :preview="{ visible, onVisibleChange: handleDrawer }"
      :src="data.current"
      :alt="data.title"
    />
    <!-- <div class="advertise-box">
      <img
        v-for="(item, index) in 4"
        :key="index"
        @click="
          hDrawer(data.resOther.data[item + 6][imageType], $t('Introduction'))
        "
        :src="data.resOther && data.resOther.data[item + 6][imageType]"
        style="cursor: pointer"
      />
    </div> -->
  </div>
</template>

<script setup>
import videoPc from "@/components/video-PC.vue";
import API from "../api/index.js";
import {
  reactive,
  onMounted,
  onBeforeMount,
  onBeforeUnmount,
  watch,
  ref,
  computed,
  nextTick,
} from "vue";
import { useI18n } from "vue-i18n";
import axios from "axios";
const { t, locale } = useI18n();
const props = defineProps({
  isLang: {
    type: Boolean,
    default: true,
  },
  videoSrc: {
    type: String,
    default: "",
  },
});

const visible = ref(false);
const data = reactive({
  commonVideoSrc: "",
  resOther: null,
  current: null,
  images: [{ src: "" }],
  backgroundImage1: "",
  backgroundImage2: "",
  backgroundImage3: "",
  backgroundImage4: "",
  backgroundImage5: "",
  backgroundImage6: "",
  backgroundImage7: "",
  stepsList: [
    {
      zhTime: "3月31日",
      zhText: "任务书发布",
      enTime: "Mar .31st",
      enText: "Mission Statement Announced",
    },
    {
      zhTime: "4月1日",
      zhText: "赛事正式启动",
      enTime: "Apr.1st",
      enText: "Start of Competition",
    },
    {
      zhTime: "6月上旬",
      zhText: "初赛作品征集提交截止",
      enTime: "Early Jun.",
      enText: "Deadline for Submission",
    },
    {
      zhTime: "6月中旬",
      zhText: "发布入围选手名单及作品",
      enTime: "Mid Jun.",
      enText: "Shortlist of Works Released",
    },
    {
      zhTime: "6月下旬",
      zhText: "公布全球入围实习名单",
      enTime: "Late Jun.",
      enText: "Intern List Released",
    },
    {
      zhTime: "7月-8月",
      zhText: "入围选手实习",
      enTime: "Jul.-Aug. ",
      enText: "Start of Internship",
    },
    {
      zhTime: "9月",
      zhText: "模型制作",
      enTime: "Sep.",
      enText: "Model Making",
    },
    {
      zhTime: "10月中下旬",
      zhText: "全球颁奖仪式",
      enTime: "Mid-late Oct.",
      enText: "Global Awards Ceremony",
    },
  ],
  isLoading: false,
});

// const imageType = ref(sessionStorage.getItem("SAIC-DESIGN-IMAGETYPE"));

const fetchVideoSrc = () => {
  API.requestVideo({
    objectName:
      "SAIC_Design/dev/index/我是汽车设计师-16-9外发版本黑底笑脸墙.m4v",
  }).then((res) => {
    data.commonVideoSrc = res.data["extranetUrl"];
  });
};
const showFn = (src) => {
  data.current = src;
  visible.value = true;
};
// 抽屉
const handleDrawer = (src, title) => {
  nextTick(() => {
    data.current = src;
    data.title = title;
    visible.value = true;
  });
};
const getOther = async () => {
  const res = await API.requestImage({
    fileDirUrl: "SAIC_Design/prod/other/",
  }).catch((e) => e);
  data.resOther = res.data;
  console.log("local", locale);
  data.backgroundImage1 =
    locale.value === "en"
      ? data.resOther[33]["extranetUrl"]
      : data.resOther[25]["extranetUrl"];
  data.backgroundImage2 = data.resOther[31]["extranetUrl"];
  data.backgroundImage3 = data.resOther[30]["extranetUrl"];
  data.backgroundImage4 = data.resOther[28]["extranetUrl"];
  data.backgroundImage5 = data.resOther[36]["extranetUrl"];
  data.backgroundImage6 = data.resOther[40]["extranetUrl"];
  data.backgroundImage7 = data.resOther[41]["extranetUrl"];
};

// 下载事件
const hDownload = () => {
  data.isLoading = true;
  let reqInstance = axios.create({
    // params: { apikey: 'bh42rxK05xklU6gs3IFSVYmR007IOQ1c' },
    headers: { loginName: "123" },
  });
  const config = {
    responseType: "blob", //这个一定要设置，否则会出现文件下载后打不开的情况
  };
  reqInstance
    .post(
      // "https://api-at.saicmotortest.com/vpdp/mobile/dev/v1/design/oss/file/oss/download",
      "/design/oss/file/oss/download",
      {
        ossFilePath: "SAIC_Design/prod/document/各赛道参考案例及赛事logo.zip",
      },
      config
    )
    .then((res) => {
      // const blob = new Blob(res, {
      const blob = new Blob([res.data], {
        type: "application/vnd.ms-excel;charset=utf-8",
      });
      let objectUrl = URL.createObjectURL(blob); //创建下载的链接
      let a = document.createElement("a");
      a.href = objectUrl;
      a.download = "各赛道参考案例及赛事logo.zip"; //设置文件名
      // a.download = "赛事资料.xlsx"; //设置文件名
      //下面这个写法兼容火狐
      a.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
      window.URL.revokeObjectURL(blob); //释放bolb对象
    })
    .then((e) => {
      data.isLoading = false;
    })
    .catch((err) => {
      data.isLoading = false;
    });
};

onMounted(() => {
  fetchVideoSrc();
  getOther();
});
</script>

<style lang="less" scoped>
@comMargin: 48px auto;
@comPadding: 0 7%;
@buttonColor: #9effc0;
@subButtonColor: #476dec;
@media screen and (min-width: 751px) {
  .task-box {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    width: 100%;
    h2 {
      font-size: 32px;
      font-weight: 700;
      margin: 10px 0 !important;
      text-align: center;
    }
    .introduce-video-box,
    .introduce-box,
    .submission-content,
    .sub-content {
      width: 70%;
      margin: 0 auto;
    }

    .introduce-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: @comPadding;
      margin: @comMargin;
      .introduce-title {
        // width: 400px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: @comPadding;
        margin: @comMargin;
        color: #ffffff;
        .title1 {
          // width: 400px;
          background-color: @buttonColor;
          color: @subButtonColor;
          font-size: 32px;
          padding: 0 10px;
          font-weight: bold;
        }
        .title2 {
          // width: 379px;
          background-color: @subButtonColor;
          color: #fff;
          font-size: 18px;
          padding: 0 10px;
           letter-spacing: 2px;
        }
        .title3 {
          // width: 379px;
          color: @subButtonColor;
          font-size: 28px;
          padding: 0 10px;
        }
        .title4 {
          // width: 477px;
          background-color: @buttonColor;
          font-size: 18px;
          font-weight: bold;
          padding: 0 10px;
        }

        .title5 {
          // width: 367px;
          background-color: @subButtonColor;
          font-size: 32px;
          font-weight: bold;
          padding: 0 10px;
        }
      }
    }

    .advertising-img {
      // padding: 405px 0;
    width: 100%;
    text-align: center;
      // background: repeat-x 0px 0px/100% 100%, repeat-x 0px 0px/100% 100%;
      img {
         width: 1080px;
      }
    }
    .car-img {
      width: 100%;
      margin-bottom: 10px;
    }
    .item-bold {
      font-size: 16px;
      font-weight: bold;
    }
    .item-liner {
      width: 100%;
      height: 41px;
      line-height: 41px;
      background-color: #fb3995;
      color: #ffffff;
      margin: 60px 0 0 0;
      text-align: center;
      font-size: 32px;
    }
    .sub-content {
      padding: @comPadding;
      margin: @comMargin;
    }
    .submission-content {
      padding: 0 5%;
      margin: 0 auto 48px;
      // line-height: 28px;
      .en-title {
        // position: relative;
        width: 75%;
        height: 24px;
        margin: 20px auto 0;
        background: #c5f946;
        text-align: center;
        span {
          display: block;
          transform: translateY(-50%);
          font-size: 20px;
        }
      }
      .submission-title {
        font-family: Alibaba PuHuiTi, Alibaba PuHuiTi;
        // font-weight: bold;
        font-size: 14px;
        color: #476dec;
        line-height: 24px;
      }
      .item-box {
          font-weight: 400;
          font-family: Alibaba PuHuiTi, Alibaba PuHuiTi;
        h5 {
          font-weight: 700;
          margin-bottom: 5px !important;
        }
        p {
          padding: 0 0 0 1em;
          line-height: 28px;
        }
      }

      .ul-item-box {
        h5 {
          font-weight: 600;
          margin-bottom: 5px !important;
        }
        p {
          line-height: 28px;
        }
      }
      .dl-item-box {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        h5 {
          font-weight: 600;
        }
        p {
          line-height: 28px;
        }
      }
      .steps {
        display: flex;
        align-items: center;
        height: 400px;
        width: 100%;
        .steps-wire {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: 2px;
          width: 100%;
          background: rgba(113, 173, 73, 0.3);
          .steps-node {
            width: 12px;
            height: 12px;
            background: #71ad49;
            border-radius: 6px;
            border: 2px solid #fff;
            margin-right: 11.5%;
            .text {
              margin-top: 23px;
              display: flex;
              flex-direction: column;
              p,
              h4 {
                white-space: nowrap;
                margin: 0 !important;
                font-size: 14px;
              }
              p {
                font-size: 12px;
              }
            }
          }
          .steps-node:nth-of-type(1) {
            .text {
              margin-left: -20px;
            }
          }
          .steps-node:nth-of-type(2n) {
            .text {
              margin-top: -100px;
            }
          }
        }
      }
      .tips {
        font-weight: 700;
        display: block;
        margin-top: 28px;
      }
    }
    /deep/.ant-btn-primary {
      background: rgba(26, 26, 26, 1);
      border: transparent;
      border-radius: 54px;
      height: 44px;
      width: 180px;
    }
    /deep/.ant-btn-primary:hover {
      background-color: #7d983a;
    }
    /deep/.ant-btn-primary:active {
      background: #527104;
    }
  }
  h4 {
    font-size: 20px;
    margin: 30px 0 30px 0 !important;
    font-weight: 700;
  }
  h5,
  p {
    font-size: 14px;
    // font-family: "AalibabaPuHui-B";
  }

  .warn {
    color: #ee0000;
    margin-right: 3px;
  }
  .item-box,
  .ul-item-box {
    margin-bottom: 16px;
  }
}
.introduce-title1 {
  // width: 441px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: @comPadding;
  margin: @comMargin;
  color: #ffffff;
  .title1 {
    // width: 180px;
    background-color: @buttonColor;
    font-size: 32px;
    color: #476dec;
    padding: 0 10px;
    font-weight: bold;
  }
  .title2 {
    // width: 314px;
    background-color: @buttonColor;
    font-size: 18px;
    padding: 0 10px;
    letter-spacing: 2px;
  }
  .title3 {
    // width: 314px;
    background-color: @subButtonColor;
    font-size: 28px;
    padding: 0 10px;
  }
  .title4 {
    width: 441px;
    background-color: @buttonColor;
    font-size: 18px;
    padding: 0 10px;
  }
  .title5 {
    width: 279px;
    background-color: @subButtonColor;
    font-size: 32px;
    padding: 0 10px;
  }
}
.introduce-title2 {
  // width: 417px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: @comPadding;
  margin: @comMargin;
  color: #ffffff;
  .title1 {
    // width: 211px;
    background-color: @buttonColor;
    color: #476dec;
    font-size: 32px;
    padding: 0 10px;
    font-weight: bold;
  }
  .title2 {
    // width: 411px;
    background-color: @buttonColor;
    font-size: 18px;
    padding: 0 10px;
      letter-spacing: 2px;
  }
  .title3 {
    // width: 411px;
    background-color: @subButtonColor;
    font-size: 28px;
    padding: 0 10px;
  }
  .title4 {
    // width: 417px;
    background-color: @buttonColor;
    font-size: 18px;
    padding: 0 10px;
  }
  .title5 {
    // width: 343px;
    background-color: @subButtonColor;
    font-size: 32px;
    padding: 0 10px;
  }
}
.introduce-title3 {
  // width: 458px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: @comPadding;
  margin: @comMargin;
  margin-top: 96px;
  color: #ffffff;
  .title1 {
    // width: 211px;
    background-color: @buttonColor;
    color: #476dec;
    font-size: 32px;
    padding: 0 10px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  .title2 {
    // width: 365px;
    background-color: @buttonColor;
    font-size: 18px;
    padding: 0 10px;
      letter-spacing: 2px;
  }
  .title3 {
    // width: 365px;
    background-color: @subButtonColor;
    font-size: 28px;
    padding: 0 10px;
  }
  .title4 {
    width: 458px;
    background-color: @buttonColor;
    font-size: 18px;
  }
  .title5 {
    // width: 316px;
    background-color: @subButtonColor;
    font-size: 32px;
    padding: 0 10px;
  }
}
.introduce-title4 {
  // width: 401px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: @comPadding;
  margin: @comMargin;
  color: #ffffff;
  .title1 {
    // width: 345px;
    background-color: @buttonColor;
    color: #476dec;
    font-size: 32px;
    padding: 0 10px;
    font-weight: bold;
  }
  .title2 {
    width: 274px;
    background-color: @buttonColor;
    font-size: 18px;
    padding: 0 10px;
       letter-spacing: 2px;
  }
  .title3 {
    // width: 274px;
    background-color: @subButtonColor;
    font-size: 28px;
    padding: 0 10px;
  }
  .title4 {
    // width: 339px;
    background-color: @buttonColor;
    font-size: 18px;
    padding: 0 10px;
  }
  .title5 {
    // width: 401px;
    background-color: @subButtonColor;
    font-size: 32px;
    padding: 0 10px;
  }
}
.introduce-title5 {
  // width: 274px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: @comPadding;
  margin: @comMargin;
  color: #ffffff;
  .title1 {
    // width: 211px;
    background-color: @buttonColor;
    color: #476dec;
    font-size: 32px;
    padding: 0 10px;
    font-weight: bold;
  }
  .title2 {
    // width: 274px;
    background-color: @buttonColor;
    font-size: 18px;
    padding: 0 10px;
    letter-spacing: 2px;
  }
  .title3 {
    // width: 274px;
    background-color: @subButtonColor;
    font-size: 28px;
    padding: 0 10px;
  }
  .title4 {
    // width: 179px;
    background-color: @buttonColor;
    font-size: 18px;
    padding: 0 10px;
  }
  .title5 {
    // width: 274px;
    background-color: @subButtonColor;
    font-size: 32px;
    padding: 0 10px;
  }
}
.introduce-title6 {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: @comPadding;
  margin-top: 48px;
  margin-bottom: 48px;
  // margin-left: -0.2%;
  color: #ffffff;
  .title1 {
    // width: 27%;
    background-color: @buttonColor;
    color: #476dec;
    font-size: 32px;
    padding: 0 10px;
    font-weight: bold;
  }
  .title2 {
    // width: 27%;
    background-color: @buttonColor;
    font-size: 18px;
    padding: 0 10px;
   letter-spacing: 2px;
  }
  .title3 {
    // width: 27%;
    background-color: @subButtonColor;
    font-size: 28px;
    padding: 0 10px;
  }
  .title4 {
    // width: 22%;
    background-color: @buttonColor;
    font-size: 18px;
    padding: 0 10px;
  }
  .title5 {
    // width: 27%;
    background-color: @subButtonColor;
    font-size: 32px;
    padding: 0 10px;
  }
  .title6 {
    // width: 27%;
    white-space: nowrap;
    text-align: center;
    color: #476dec;
    font-family: Alibaba PuHuiTi, Alibaba PuHuiTi;
    font-weight: 400;
    font-size: 12px;
    padding: 0 10px;
  }
}
.introduce-title7 {
  // width: 333px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: @comPadding;
  margin: @comMargin;
  color: #ffffff;
  .title1 {
    // width: 333px;
    color: #476dec;
    background-color: @buttonColor;
    font-size: 32px;
    padding: 0 10px;
    font-weight: bold;
  }
  .title2 {
    // width: 333px;
    background-color: @subButtonColor;
    font-size: 18px;
    padding: 0 10px;
   letter-spacing: 2px;
  }
  .title3 {
    // width: 333px;
    background-color: @subButtonColor;
    font-size: 28px;
    padding: 0 10px;
  }
  .title4 {
    // width: 333px;
    background-color: @buttonColor;
    font-size: 18px;
    padding: 0 10px;
  }
  .title5 {
    // width: 268px;
    background-color: @subButtonColor;
    font-size: 32px;
    padding: 0 10px;
  }
}
.introduce-title8 {
  // width: 305px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: @comPadding;
  margin: @comMargin;
  color: #ffffff;
  .title1 {
    // width: 305px;
    background-color: @buttonColor;
    font-size: 32px;
    font-weight: bold;
    padding: 0 10px;
    color: #476dec;
  }
  .title2 {
    // width: 305px;
    background-color: @buttonColor;
    font-size: 18px;
    padding: 0 10px;
    letter-spacing: 2px;
  }
  .title3 {
    // width: 305px;
    background-color: @subButtonColor;
    font-size: 28px;
    padding: 0 10px;
  }
  .title4 {
    // width: 243px;
    background-color: @buttonColor;
    font-size: 18px;
    padding: 0 10px;
  }
  .title5 {
    padding: 0 10px;
    background-color: @subButtonColor;
    font-size: 32px;
  }
}
.button-type5 {
  background-color: rgba(158, 255, 192, 1) !important;
  color: rgba(71, 109, 236, 1) !important;
}
.button-type5:hover {
  background-color: rgba(145, 146, 168, 1) !important;
  color: #ffffff !important;
}
.button-type5:active {
  background-color: #476dec !important;
  color: #ffffff !important;
}
.localeEn {
  .submission-content > div:nth-child(1) {
    font-weight: bold;
  }
  .submission-content > div:nth-child(2) {
    font-weight: bold;
  }
  div,
  p,
  span {
  font-family: Alibaba PuHuiTi, Alibaba PuHuiTi;
  }
}
.localeZh {
  div,
  p,
  span {
  font-family: Alibaba PuHuiTi, Alibaba PuHuiTi;
  }
}
.evaluationBox {
  display: flex;
  flex-wrap: wrap;
  div {
    width: 50%;
    text-align: center;
    margin: 10px 0;
  }
}
.TimeArrangement {
  margin-left: 10px;
  margin-top: 30px;
}
.articleText{

font-family: Alibaba PuHuiTi, Alibaba PuHuiTi;
font-weight: 400;
font-size: 14px;
color: #000000;
line-height: 28px;
text-align: left;
font-style: normal;
text-transform: none;
}
.lineHeight{
line-height: 28px;
};
</style>
