<template>
  <div>
    <header-phone
      :src="data.commonSrc && data.commonSrc[0]['extranetUrl']"
      :isGoBack="true"
    >
      <template v-slot:nav>
        <a @click="router.push('/')">{{ $t("HomePage") }}</a>
      </template></header-phone
    >
    <div class="contentBox">
      <h2>{{ data.detail.workName }}</h2>
      <!-- <img
        class="awards"
        v-if="data.detail.awardsSrc == 1"
        src="@/assets/medal1.png"
        alt=""
      />
      <img
        class="awards"
        v-if="data.detail.awardsSrc == 2"
        src="@/assets/medal2.png"
        alt=""
      />
      <img
        class="awards"
        v-if="data.detail.awardsSrc == 3"
        src="@/assets/medal3.png"
        alt=""
      />
      <img
        class="awards"
        v-if="data.detail.awardsSrc == 4"
        src="@/assets/medal4.png"
        alt=""
      />
      <img
        class="awards"
        style="width: 110px"
        v-if="data.detail.awardsSrc == 5"
        src="@/assets/medal5.png"
        alt=""
      />
      <img
        class="awards"
        v-if="data.detail.awardsSrc == 31"
        src="@/assets/medal6.png"
        alt=""
      /> -->
      <div
        class="bear-the-palm-detail"
        :style="{
          width:
            data.detail.awardsSrc == 5
              ? '129px'
              : data.detail.awardsSrc == 38
              ? '129px'
              : '84px',
        }"
      >
        <img class="iconPC" :src="data[`iconUrl${data.detail.awardsSrc}`]" />
        <span class="awardsText" :class="`awardsColor${data.detail.awardsSrc}`">
          {{ data.detail.awards }}
        </span>
      </div>
      <p>
        {{ data.detail.name }} | {{ data.detail.country
        }}{{ data.detail.category != "欧洲" ? " • " : ""
        }}{{ data.detail.category != "欧洲" ? data.detail.category : "" }}
      </p>
      <div class="video-content">
        <!-- <div class="vido-box-overflow"> -->
        <div class="video-item" v-for="(ele, i) in data.dataList" :key="i">
          <video-pc v-if="ele.isVideo != -1" :videoSrc="ele['extranetUrl']" />
          <img v-if="ele.isIMG != -1" :src="ele['extranetUrl']" alt="" />
          <img v-if="ele.isPNG != -1" :src="ele['extranetUrl']" alt="" />
          <img v-if="ele.isGif != -1" :src="ele['extranetUrl']" alt="" />
        </div>
        <div style="width: 100%; height: 84px; background: #fff"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { reactive, onMounted, onBeforeMount, ref } from "vue";
import API from "../api/index.js";
import videoPc from "@/components/video-PC.vue";
import headerPhone from "@/components/header-phone.vue";
const route = useRoute();
const router = useRouter();
const data = reactive({
  detail: {},
  dataList: [],
  commonSrc: null,
  iconUrl1: require("@/assets/a.png"),
  iconUrl2: require("@/assets/b.png"),
  iconUrl3: require("@/assets/c.png"),
  iconUrl4: require("@/assets/d.png"),
  iconUrl5: require("@/assets/e.png"),
  iconUrl31: require("@/assets/f.png"),
  iconUrl37: require("@/assets/g.png"),
  iconUrl38: require("@/assets/h.png"),
});
// const imageType = ref(sessionStorage.getItem("SAIC-DESIGN-IMAGETYPE"));
data.detail = JSON.parse(route.query.detail);
onBeforeMount(() => {
  getList();
  fetchComonImage();
});
const getList = async () => {
  const res = await API.requestImage({
    fileDirUrl: `SAIC_Design/prod/${data.detail.annual}${data.detail.cloneSrc}/`,
  }).catch((e) => e);
  res.data.forEach((item) => {
    data.dataList.push({
      extranetUrl: item["extranetUrl"],
      extranetUrl: item["extranetUrl"],
      isIMG: item["extranetUrl"].indexOf(".jpg"),
      isPNG: item["extranetUrl"].indexOf(".png"),
      isVideo: item["extranetUrl"].indexOf(".mp4"),
      isGif: item["extranetUrl"].indexOf("gif"),
    });
  });
};

const fetchComonImage = () => {
  API.requestImage({
    fileDirUrl: "SAIC_Design/prod/index/common/",
  }).then((res) => {
    data.commonSrc = res.data;
  });
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 750px) {
  .layout-header-M {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 44px;
    padding: 0 24px;
    display: flex;
    // flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    // transition: all 0.5s;
    .goBack {
      font-size: 16px;
      line-height: 22px;
    }
  }
  .contentBox {
    width: 100%;
    padding: 6% 5% 0 5%;
    margin-top: 44px;
    h2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 34px;
    }
    .awards {
      width: 82px;
      height: 28px;
      margin: 16px -8px;
    }
    p {
      font-size: 14px;
      line-height: 20px;
    }
    .video-content {
      margin-top: 20px;
      // position: relative;
      // width: 100%;
      // height: 0;
      // padding-bottom: 130%;
      .video-item {
        width: 100%;
        margin-bottom: 16px;
        img {
          // position: absolute;
          width: 100%;
          // height: 100%;
        }
      }
    }
  }
}
.bear-the-palm-detail {
  display: flex;
  align-items: center;
  // width: 84px !important;
  height: 32px;
  background: rgba(0, 0, 0, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 28px 28px 28px 28px;
  margin: 10px 0 16px 0;

  
}
.iconPC {
  height: 24px !important;
  width: 24px !important;
  margin: 4px 4px;
}
.iconMobel {
  height: 24px !important;
  width: 24px !important;
  margin: 0px 2px;
}
.awardsColor1 {
  color: #feb51c !important;
}
.awardsColor2 {
  color: #c9ccd3 !important;
}
.awardsColor3 {
  color: #e49b74 !important;
}
.awardsColor4 {
  color: #beafe0 !important;
}
.awardsColor5 {
  color: #feb51c !important;
}
.awardsColor31 {
  color: #9bcaff !important;
}
.awardsColor37 {
  color: #60bf86 !important;
}
.awardsColor38 {
  color: #84d4ff !important;
}
</style>
