<template>
  <div class="upload-wrapper">
    <draggable
      v-model="fileList"
      group="project"
      animation="600"
      :disabled="name !== '作品'"
    >
      <template #item="{ element, index }">
        <div
          class="file-item"
          :title="element.fileName"
          @click="handleClick(element)"
        >
          <div class="file-wrapper">
            <img v-if="element.src" :src="element.src" />
            <template v-else>
              <file-pdf-outlined v-if="element.fileType.includes('pdf')" />
              <file-word-outlined
                v-if="
                  element.fileType.includes('doc') ||
                  element.fileType.includes('docx')
                "
              />
              <file-zip-outlined v-if="element.fileType.includes('zip')" />
              <file-image-outlined
                v-if="
                  element.fileType.includes('png') ||
                  element.fileType.includes('jpg') ||
                  element.fileType.includes('jpeg')
                "
              />
              <video-camera-outlined
                v-if="
                  element.fileType.includes('mp4') ||
                  element.fileType.includes('mov')
                "
              />
            </template>
          </div>
          <span class="file-title">{{ element.fileName }}</span>
          <div class="delete" @click.prevent.stop="fileList.splice(index, 1)">
            删除
          </div>
        </div>
      </template>
    </draggable>
    <div v-if="loading" class="loaidng-upload">
      <img src="@/assets/loading.png" />
    </div>
    <a-upload
      v-if="!loading && fileList && fileList.length < props.limit"
      class="avatar-uploader"
      v-model:value:file-list="fileList"
      :multiple="multiple"
      :accept="props.accept"
      list-type="picture-card"
      :showUploadList="false"
      :before-upload="beforeUpload"
      :customRequest="(file) => transformFile(file)"
    >
      <div>
        <p
          style="
            font-size: 30px;
            line-height: 40px;
            transform: scale(1.8);
            margin-top: 12px;
          "
        >
          +
        </p>
        <div class="ant-upload-text">{{ props.tips }}</div>
      </div>
    </a-upload>
    <a-drawer
      height="90%"
      :title="data.title"
      placement="bottom"
      :visible="visible"
      @close="onClose"
    >
      <div v-if="download" class="loading-drawer">
        <img src="@/assets/loading.png" />
      </div>
      <template v-else>
        <img v-if="imageSrc" :src="imageSrc" />
        <div v-else-if="docSrc" id="docx_detail"></div>
        <video v-else-if="videoSrc" :src="videoSrc" controls></video>
        <vue-pdf-embed v-else-if="pdfSrc" :source="pdfSrc"></vue-pdf-embed>
      </template>
    </a-drawer>
  </div>
</template>

<script setup>
import {
  FilePdfOutlined,
  FileWordOutlined,
  FileZipOutlined,
  FileImageOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons-vue";
import API from "@/api";
import OSS from "ali-oss";
import vuePdfEmbed from "vue-pdf-embed";
import { renderAsync } from "docx-preview";
import draggable from "vuedraggable";
import {
  ref,
  inject,
  watch,
  nextTick,
  reactive,
  onMounted,
  defineEmits,
} from "vue";
import { useI18n } from "vue-i18n";
import { message, Modal } from "ant-design-vue";

const { t, locale } = useI18n();
const indicator = inject("indicator");
const loading = ref(false);
const fileList = ref([]);
const visible = ref(false);
const download = ref(false);
const pdfSrc = ref();
const docSrc = ref(false);
const imageSrc = ref();
const videoSrc = ref();

const emits = defineEmits(["change"]);

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
const props = defineProps({
  oss: {
    type: Object,
    default: () => {},
  },
  tips: {
    type: String,
    default: "",
  },
  name: {
    type: String,
    default: "",
  },
  limit: {
    type: Number,
    default: 99999,
  },
  accept: {
    type: Array,
    default: () => [],
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  arguments: {
    type: Object,
    default: () => {},
  },
});

const data = reactive({
  title: "",
});

const onClose = (state) => {
  visible.value = false;
};

// 点击预览文件
const handleClick = (value) => {
  visible.value = true;
  download.value = true;
  pdfSrc.value = null;
  docSrc.value = null;
  imageSrc.value = null;
  videoSrc.value = null;
  API.download({
    ossFilePath: value.fileUrl,
  }).then((res) => {
    download.value = false;
    if (value.fileType.includes("pdf")) {
      let blob = new Blob([res], { type: "application/pdf" });
      pdfSrc.value = window.URL.createObjectURL(blob);
    }
    if (value.fileType.includes("doc") || value.fileType.includes("docx")) {
      docSrc.value = true;
      nextTick(() => {
        renderAsync(res, document.querySelector("#docx_detail"));
      });
    }
    if (value.fileType.includes("video")) {
      let blob = new Blob([res], { type: "application/mp4" });
      videoSrc.value = window.URL.createObjectURL(blob);
    }
    if (
      value.fileType.includes("png") ||
      value.fileType.includes("jpg") ||
      value.fileType.includes("jpeg")
    ) {
      let blob = new window.Blob([res], { type: "image/png" });
      const url = window.URL.createObjectURL(blob);
      value.src && (imageSrc.value = value.src);
      !value.src && (imageSrc.value = url);
    }
  });
};

// 上传前文件大小格式校验
const beforeUpload = (file) => {
  if (file.type.includes("video") && file.size > 1024 * 1024 * 500) {
    message.error(t("videoSize"));
    return false;
  }
  if (file.size > 1024 * 1024 * 30 && !file.type.includes("video")) {
    message.error(t("fileSize"));
    return false;
  }
  return true;
};

// 上传操作
const transformFile = (file) => {
  let path = null;
  let pathUrl = null;
  loading.value = true;
  let year = new Date().getFullYear();
  let emailReg = new RegExp(/^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/);
  let idCardReg = new RegExp(
    /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/
  );
  // 校验赛区是否选择
  if (!props.arguments.zone) {
    message.error(t("zoneValid"));
    loading.value = false;
    return;
  }
  // 校验邮箱是否填写
  if (!props.arguments.email) {
    message.error(t("emailValid"));
    loading.value = false;
    return;
  }
  // 校验邮箱规则
  if (!emailReg.test(props.arguments.email)) {
    message.error(t("emialFormat"));
    loading.value = false;
    return;
  }
  // 国内OSS直传路径拼接
  if (props.arguments.zone == "中国") {
    if (!props.arguments.idCard) {
      message.error(t("idNumberValid"));
      loading.value = false;
      return;
    }
    if (!idCardReg.test(props.arguments.idCard)) {
      message.error(t("idNumberFormat"));
      loading.value = false;
      return;
    }
  }
  path = `SAIC_Design/prod/player/temp/${year}/${props.arguments.ipAddress}/${props.arguments.timestamp}/${file.file.name}`;
  pathUrl = `SAIC_Design/prod/player/${year}/${props.arguments.email}/${props.arguments.idCard}/${file.file.name}`;
  // 国外路径拼接
  if (props.arguments.zone != "中国") {
    pathUrl = `SAIC_Design/prod/player/${year}/${props.arguments.email}/${file.file.name}`;
  }
  // OSS上传
  let client = new OSS({
    region: "oss-cn-shanghai",
    accessKeyId: props.oss.accessKeyId,
    accessKeySecret: props.oss.accessKeySecret,
    stsToken: props.oss.securityToken,
    bucket: props.oss.bucket,
    endpoint: 'oss-cn-shanghai.aliyuncs.com', //外网地址
    // endpoint: "oss-cn-shanghai-internal.aliyuncs.com", //内网oss地址

    timeout: 1000 * 60 * 5,
  });
  client
    .put(path, file.file)
    .then((clientRes) => {
      if (clientRes.res.status !== 200) {
        message.error(t("uploadError"));
        loading.value = false;
        return;
      }
      if (file.file.type.includes("image")) {
        // 图片转base64
        getBase64(file.file).then((img) => {
          let info = {
            fileName: file.file.name,
            fileType: file.file.type,
            moduleName: props.name,
            serialNo: fileList.length,
            fileUrl: pathUrl,
            src: img,
          };
          props.name === "作品" && fileList.value.push(info);
          props.name !== "作品" && (fileList.value[0] = info);
        });
      } else {
        let info = {
          fileName: file.file.name,
          fileType: file.file.type,
          moduleName: props.name,
          serialNo: fileList.length,
          fileUrl: pathUrl,
        };
        props.name === "作品" && fileList.value.push(info);
        props.name !== "作品" && (fileList.value[0] = info);
      }
      loading.value = false;
    })
    .catch((err) => {
      loading.value = false;
      if (err.status == 403) {
        emits("change", false);
        Modal.error({
          title: t("uploadError"),
          content: t("uploadTokenError"),
        });
        return;
      }
      message.error(t("uploadError"));
    });
};
defineExpose({
  fileList,
});
</script>

<style lang="less"></style>
