export default {
    enterEurope: 'Enter Europe',
    AboutUs: 'About us',
    Team: 'Team',
    Works: 'Works',
    SDCMegagame: 'SDC',
    Chinese: 'Chinese',
    English: 'English',
    Propaganda: 'Propaganda of SAIC Motor Competition',
    OurStory: 'Our Story',
    StoryDesc: 'SAIC DESIGN is the design center under SAIC Motor R&D Innovation Headquarters. We are responsible for the design of vehicles under SAIC\'s Roewe, MG and Rising Auto.\n\nSince its establishment, after more than ten years of rapid development, SAIC DESIGN has grown into one of the most important forces in the field of China\'s automotive design, and officially renowned as the national industrial design center in 2021.',
    OfficialWeibo: 'Official Weibo',
    OfficialWeChat: 'Official WeChat',
    OfficialINS: 'Official INS',
    OurTeam: 'Our Team',
    TeamIntro: 'SAIC Motor R&D Innovation Headquarters Design Center, or SAIC Design Center, is one of the largest automotive design institutions in the region of Asia. SAIC Design brings over 300 outstanding design talents with automotive design dreams from all over the world together, as well as multiple international design experts, and is equipped with world-class software and hardware facilities and styling development equipment. The team is capable of developing the whole industry chain from user research, advanced-design to vehicle design, and continues to expand to the field of integrated innovative design such as brand design and service design. Based in China, SAIC Design\'s global team will integrate global resources and continue to create more excellent automotive design works for the global market in the future.',
    Leader: 'Shao Jingfeng',
    NameIntro: "Chief Design Officer, SAIC Motor R&D Innovation HeadquartersGeneral Manager of SAIC UK Technology Center.\n\nSince October 2011, Shao Jingfeng has worked in the technical center of SAIC, responsible for the styling design of SAIC's independent brands such as Roewe, MG and Rising Auto. Shao built an international design team with Shanghai Design Headquarters, London Prospective Design Center and other overseas design studios. Shao was awarded by the Red Dot, IF, G-Mark, Shanghai Creative Youth Talents Awards , 2021 Forbes China Top Ten Designers Award, 2021 China Top Ten Chief Design Officers, 2021 Design Guanghua Longteng Award China Design Contribution Award Silver Medal and so on.\n\nAs a member of the 12th Shanghai Senior Youth Federation and a representative of the Shanghai Municipal Trade Union, Shao Jingfeng also serves as an assessment experts of the Ministry of Education of China, a part-time professor and mentor in Tongji University, Shanghai Jiao Tong University, Central Academy of Fine Arts, East China University of Science and Technology. Founded by Shao Jingfeng since 2013, the SAIC Design International Challenge has sent a large number of high-quality talents to the entire Chinese automotive design industry and continuously promoted China's automobile design to the international advanced level.",
    Name1: 'Huang Qinghui',
    Position1: 'Director of Global Design Platform, SAIC Design',
    Name2: 'Shao Changshan',
    Position2: 'esign Director of ROEWE, SAIC Design',
    Name3: 'Martin Kropp',
    Position3: 'Vice Global Design Director of SAIC DESIGN',
    Name4: 'Lu ying',
    Position4: 'Design Director of MG Brand,SAIC Design',
    Name5: 'Oleg Son',
    Position5: 'Vice Global Design Director of SAIC DESIGN',
    Name6: 'Carl-Gotham',
    Position6: 'Advanced Design Director of SAIC DESIGN ADVANCED LONDON',
    Name7: 'Nicola Ehrenberg-Uhlig',
    Position7: 'Vice Global Design Director of SAIC DESIGN',
    Brand: 'Our Work',
    introduction: 'As the vanguard of China\'s automotive industry, SAIC has been continuously contributing to the independent research and development of China\'s automotive industry, and has produced many excellent automotive design works recognized by the market and users. In the past decade, the three independent brands of Roewe, MG and Rising Auto have produced a total of 56 mass production projects and 7 concept car projects, each of which strives to create products that integrate technology, texture, comfort and design aesthetics.',
    // Desc: 'In the past decade, SAIC\'s design team has designed many successful products and has won more than 30 world-class industrial design awards in the global industrial design community, including the Red Dot Award, the IF Design Award, and the G-MARK Design Award.',
    ABriefIntroduction: 'A brief introduction',
    brand1: '精致优雅，始终是荣威的产品特质',
    brand1Desc: '优雅，是设计品质的外显。整车的造型语言极致舒展，轻盈流畅；减法设计让车身没有多余的元素，简洁大方。协调而富有节奏的姿态，表达由内而外的优雅,塑造由内而外的高级。	',
    brand2: '荣威：稳健的设计进化',
    brand2Desc: '在消费者相对熟悉的设计语言中，结合趋势稳步创新，不夸张、不激进、不保守。荣威D7车长4890mm，宽1872mm, ⾼1510mm, 轴距2810mm，以B级的车⾝尺⼨，让D7拥有强⼤的⽓场和充⾜的内饰空间。',
    brand3: '纯粹之平衡·完整·温润',
    brand3Desc: '平衡：Cd = 0.23，平衡乘坐空间与续航里程的系统最优解\n\n完整：各种设计特征追求完整的形态,如前舱机盖的分缝完整切割，营造完整纯粹的感官体验\n\n温润：大巧不工，D7营造品质感不仅仅是从细节入手，而首要是从打造高质量的曲面和体量入手。圆润的形态和倒角给人浑然一体的感受',
    brand4: '精妙品质背后是极致工艺的支撑',
    brand4Desc: '百万级豪车的工艺品质、电感展翼尾灯与双五幅追风轮毂，每一个设计思考，每一个元素，都是一件作品。',
    brand5: '整体设计理念——“高能浪漫主义”美学',
    brand5Desc: 'MG Cyberster代表了MG品牌“高能浪漫主义”设计美学的开创。它由上汽伦敦前瞻设计中心与上海设计总部联手打造，是上汽设计师们探索在智能电驱时代如何传达速度激情与真实情感的大胆尝试。人们对浪漫自由的追求是永恒不变的，而自由、感性、对生命力的天然向往，正是跑车特有的魅力，也是百年MG品牌不变的精神内核。我们用自己的方式探索各种可能，勇敢地迭代进化，让人性与科技合二为一，重构一颗奔跑的智能之心。',
    brand6: '电动剪刀门·电动软顶',
    brand6Desc: '剪刀门的设计为用户带来震撼心灵的仪式感 进出更浪漫优雅。\n\n电动软顶致敬 MG 最经典的软顶敞篷历史 展现最纯粹的欧式Roadster 魅力。',
    brand7: '纯正的欧式敞篷跑车比例·扎实的跑车姿态：',
    brand7Desc: 'MG Cyberster 拥有经典的两座跑车比例：修长的前舱、靠后的A柱和极致的轮身比。在全新电动架构的支持下，车头压低斜切，强烈的低趴姿态得以显现。座舱部分透气轻盈，整车轮廓简洁顺畅。\n\n姿态代表了一辆车三维形态的整体感知，对于跑车尤为重要。宽大的四轮撑满轮拱，配合挺拔的车肩和独特的收腰处理，构建强劲的扎地感。犹如匍匐着的猫科动物，后肢下压蹬地，时刻准备前冲。',
    brand8: '“势能线”设计  Momentum Line',
    brand8Desc1: '豪华｜优雅',
    brand8Desc2: '饱满的形面带来现代豪华感，两端舒展的线形又增添了一丝优雅的气息。双拱式的腰线设计结合饱满挺拔的侧面造型，增加了整车的豪华感，预示着纯电动车平静表面下隐藏的强劲内心。',
    brand9: '空气动力专属设计',
    brand9Desc1: '迎风刃',
    brand9Desc2: '在纯电时代需要“破风”，要高效地在气流中快速前行车头快速下压，环宇之光高度融合于形面，形成饱满有力的一体感车头结构。',
    brand9Desc3: '融合',
    brand9Desc4: '保险杠两侧自然打开，开孔与远近光一体式大灯融合，直接贯通尾部，与车身型面起承转合融为一体。有效疏导底部气流，配合更顺滑的形面，造就飞凡F7卓越的空气动力学表现。',
    brand10: '掀背式行李箱',
    brand10Desc1: '运动｜优雅｜效率',
    brand10Desc2: '流畅溜背造型结合掀背式行李箱设计，整体外轮廓修长舒展一气呵成，展现运动优雅姿态的同时，达成了空间使用效率与气动效率的最优解。尾箱与尾灯的精密匹配更体现出极致精湛的工艺之美。',
    workEles: '更多作品',
    // brandInfo: "In the past decade, SAIC's design team has designed many successful products and has won more than 30 world-class industrial design awards in the global industrial design community, including the Red Dot Award, the IF Design Award, and the G-MARK Design Award.",
    work1: 'MG MAZE CONCEPT',
    work1Desc: '来自上汽设计中心伦敦前瞻设计工作室，荣获2022红点概念设计奖',
    work2: 'The brand-new MG5',
    work2Desc: '荣获2021日本优良设计奖G-Mark',
    work3: 'ROEWE Next-Gene Car Key',
    work3Desc: '荣获2020 IF设计奖',
    work4: 'ROEWE RX5',
    work4Desc: '荣获2017 IF设计奖',
    work5: 'Rising Auto R7',
    work5Desc: '极智高阶纯电SUV',
    work6: 'MG MULAN',
    work6Desc: '中国电车的欧洲销冠，创造中国汽车品牌的出海新高度',
    work7: 'R-Aura CONCEPT',
    work7Desc: '荣获2020红点设计概念奖',
    work8: 'ROEWE Marvel X',
    work8Desc: '荣获2018 G-Mark全球设计大奖',
    work9: 'ROEWE i6',
    work9Desc: '荣获2017 G-Mark全球设计大奖',
    workName: 'Name of work',
    SDC: 'SAIC Design Challenge',
    Detail: 'Detail',
    Copyright: '©Copyright 2020 SAIC Group',
    Adress: 'Hu ICP Bei No. 09093484-5',
    NetWork: 'HGB No. 31011402001176',
    Language: 'Language switch',
    ContestIntro: 'The original intention of the SAIC Design International Challenge (SDC) is to find and educate the rising stars in Chinese automobile design. Since 2013, the SAIC Design International Challenge has been held for ten times and has become one of the most influential professional-level automobile design competitions in China. In the past decade, the SDC has attracted 3,558 design students to participate in the competition, and 220 students have been admitted to SAIC Design internships, of which more than 90 have subsequently joined SAIC Design Center, becoming the backbone of SAIC Design Center.\n\nAt the same time, in the past decade, 216 universities from 10 countries have formed a closed loop of "industry-university-research" cooperation through SDC. The SAIC Design International Challenge has also become a solid link between global design academies and the Chinese automotive industry.',
    // 大赛页面 start
    CountDown:'Count Down',
    NotSubmissionPeriodYet:'Submission starts on April 1st',
    UploadPortfolios:'Upload Portfolios',
    TheSubmissionPeriodIsOver:'the submission period is over',
    SubmissionDue:'Submission due: June 24th, 23:59',
    PleaseLookForwardTo:"Please look forward to the next design challenge",
    ThisTournament:'This Tournament',
    TaskBokes:'TaskBokes',
    PreviousExcellentWorks:'Previous Excellent Works',
    TitleOfEntry:'TitleOfEntry',
    CompetitionText1:"In a fast-paced era with content overload and digital news cycles driving an ever-changing landscape, consumers’ tastes and trust levels have been challenged. Consumers are becoming increasingly sceptical and look for reassurance.",
    CompetitionText2:"\n",
    CompetitionText3:"They seek genuine experiences with brands that align with their values and provide meaningful experiences. This demand for authenticity, trust, transparency and sustainability has encouraged companies to revaluate their branding strategies and the way they develop future product line-up.",
    CompetitionText4:"\n",
    CompetitionText5:"Today we need to design products to build real relationships with our audience.",
    CompetitionText6:"",
    CompetitionText7:"",
    DesignTask:"Design Task",
    Introduction:"Introduction",
    Download:"Download",
    Focus:"Focus",
    RaceTrack:"Race Track",
    HomePage:"Home",
    Back:"Back",
    StylingDesign:"Styling Design (Main)",
    ColorMaterial:"CMF (Color, Material & Finishing) Design",
    DigitalVisualizationDesign:"Digital Visualization Design",
    BrandResearchDesignStrategy:"Brand Research & Design Strategy",
    HumanMachineInterfaceDesign:"Human-machine Interface Design",
    All:"ALL",
    Screen:"Screen",
    Hint:"Please upload your project through webpage.",
    REALWORLD:"REAL WORLD：New Rationality and New Emotion",
    CompetitionText8:"This decade of competition is “unusual”!",
    CompetitionText9:"We have gained so many novel design ideas .",
    CompetitionText10:"However, with the change of the current environment, ",
    CompetitionText11:"we call on design to return to the new rationality.",
    CompetitionText12:"Looking for the inner rules of how things develop in the real world:",
    CompetitionText13:"What is the right design? How to do the right design? Start again. Seek new understanding and create new feelings for a better life!",
    submission:"submission content",
    CompetitionText14:"1.Self Introduction：",
    CompetitionText15:"Resume PDF*1;100-Second Self-Introduction Video MP4*1.",
    CompetitionText16:"If you have a portfolio, you can submit it (not a must).",
    CompetitionText17:"2.Two display boards:",
    CompetitionText18:"A3 size [420 × 297mm], JPEG format, resolution ratio not less than 300dpi, horizontal typesetting;The content need to include: Sketching, front and rear 45 degree view, 3D play-up effect results, front and side view of package.",
    CompetitionText19:"3.The proposal show sheet can：",
    CompetitionText20:"Also include design intent, car package, conceptual sketch, ergonomics, detailed design, work explanation and other contents (not a must).",
    CompetitionText21:"4.3D digital model files of design works:",
    CompetitionText22:"Acceptable software formats include but not limited to alias and pro_ E、UG、Catia、 Maya、 3D Max、 Rhino、Blender.",
    RisksAndOpportunities:"Risks and Opportunities of Artificial Intelligence",
    CompetitionText23:"Work requirements：",
    CompetitionText24:"Generative AI (AIGC) has become another hot topic after NFT, metaverse and Web3.0. What risks and opportunities will it bring to the consumers, the industries and the society? (Original and comprehensive views is required; Data are authentic and sourced; Clear frame with good picture will easy to read and look attractive.)",
    CompetitionText25:"Submission content",
    CompetitionText26:"1.Self Introduction:",
    CompetitionText27:"Resume PDF*1；100-Second Self-Introduction Video MP4*1",
    CompetitionText28:"2.Research Report:",
    CompetitionText29:"(PDF format, single page 16:9 horizontal layout).",
    CompetitionText30:"A「wow」function",
    CompetitionText31:"Please watch the themed video “THE REAL WORLD”. This competition returns to rational thinking and usability.",
    CompetitionText32:"Please based on your understanding of new energy vehicles and the industry, create an 「experienced」 intelligent cockpit by HMI design.",
    CompetitionText33:"Submission content",
    CompetitionText34:"2.DESIGN CONCEPT ANALYSIS：",
    CompetitionText35:"PDF，RGB; width 850px; height is not limited. Ux concepts, visual concepts, and interpretation of design highlights can be concluded. Remember to clarify the car scene, show insight and effective and feasible solutions;The size needs to be comfortable to read on the mobile phone, the font should be clear and readable.",
    CompetitionText36:"3.UI：",
    CompetitionText37:"Jpeg; RGB; resolution ratio not less than 300dpi, ipk 1920*720px; cr 1920*1080px(at least 3 picture).",
    CompetitionText38:"4.VIDEO：",
    CompetitionText39:"Mp4; less than 500m; less than 2 mins Make the audience clearly understand the design concept, design context and design goals",
    CompetitionText40:"5.ADDITIONAL WORK：",
    CompetitionText41:"Additionally upload is available, such as personal talent/work model/prototypes/appendix/related websites/personal works.The format is not limited, but it is recommended to be an easy to open and easy to review.",
    SubmissionSpecification:"Submission specification",
    CompetitionText42:"(date + author's name + institution + personal introduction)",
    CompetitionText43:"2.Concept video:",
    CompetitionText44:"(date + author’s name + institution + concept video)",
    CompetitionText45:"3.UI design renderings:",
    CompetitionText46:"(date + author name + institution + UI + page content)",
    CompetitionText47:"4.Documentation:",
    CompetitionText48:"(date + author name + institution + concept analysis)",
    CompetitionText49:"5.Extra works:",
    CompetitionText50:"(date + author name + institution + exhibition works)",
    CompetitionText51:"Dreamer of today",
    CompetitionText52:"Please design an innovative CMF design scheme based on the interpretation of the real world video and combined with the development trend of electric vehicle design.",
    CompetitionText53:"Combined with the concept of environmental protection and family life, give bright design innovation and new materials (family is the dream of the moment, how to make a car that brings happiness and warmness.",
    CompetitionText54:"1.Project report",
    CompetitionText55:"Theme explanation/population analysis/design trend analysis/storyboard/material board/material board/interior and exterior decoration renderings/physical materials",
    CompetitionText56:"2.Bonus points",
    CompetitionText57:"videos that assist in displaying CMF design schemes. (No mandatory requirements)",
    CompetitionText58:"Dimension requirement",
    CompetitionText59:"1.Design report:",
    CompetitionText60:"PDF format, single page 16:9 horizontal layout.",
    CompetitionText61:"2.Design and display video:",
    CompetitionText62:"MP4 format, less than 500M, duration no more than 2min, 1920*1080.",
    CompetitionText63:"Super product power",
    CompetitionText64:"The digital visualization focuses on innovative thinking, re-innovates the static visual display and dynamic effect presentation of the car models of the previous competition winners, highlights the product modeling design aesthetics. On the basis of not changing the original modeling design, the static and dynamic pictures can be redefined. So does scenarios, showing the new feeling of modeling design under the new situation.",
    CompetitionText65:"By showing the diversified perception and experience of cars in different time periods and different scenes, we can actively think and explore people's real life scenes or future life style.",
    CompetitionText66:"Can be done in video or rendering ways. The content of the work should be healthy, positive, optimistic, and has a good mental outlook, without pornographic, violent, bloody, political and other negative content. All materials should indicate the source (original or online materials).",
    CompetitionText67:"1.Static rendering",
    CompetitionText68:"Static image rendering (more than 9 images, size 3840*2160, scale 16:9, format png or jpeg)",
    CompetitionText69:"2.Animation",
    CompetitionText70:"The works can be submitted in the form of video files, which can be combined with audio-visual language and edited into a film. The stories can be appropriately simplified, focusing on the modeling charm of the products and highlighting the characteristics of the products. The direction of the film tone can follow the feeling of the products. It can enhance the identity of the brand image and enhance meaning.",
    CompetitionText71:"3.Storyboard script",
    CompetitionText72:"(sketching/computer drawing/video material splicing or mind map): mainly explains the film's story outline, concept description, understanding of modeling and brand, etc.",
    CompetitionText73:"(date + author's name + institution + personal introduction)",
    CompetitionText74:"(date + author’s name + institution + concept video)",
    CompetitionText75:"3.Rendering image：",
    CompetitionText76:"(date + author’s name + institution + Rendering image①)",
    CompetitionText77:"1.Participants please visit our official website:<span style='font-weight:700'>saicdesign.com</span> to register.",
    CompetitionText78:"2.Fill in your personal information according to the prompts.",
    CompetitionText79:"3.Upload a profile photo (.jpg, resolution more than 300dpi).",
    CompetitionText80:"4.Upload projects.",
    CompetitionText81:"Tips",
    CompetitionText82:"The Organizing Committee of SAIC DESIGN CHALLENGE reserves the right of final interpretation of the rules of this competition.",
    CompetitionText83:"Mail:saicdesign@saicmotor.com",
    CompetitionText84:"Click Design Task to view more details",
    Tagline:"This decade of competition is “unusual”!</br> We have gained so many novel design ideas.</br> However, with the change of the current environment,</br>we call on design to return to the new rationality.</br> Looking for the inner rules of how things develop in the real world:</br> What problems do design solve? What is the right design? How to do the right design? Start again.</br> Seek new understanding and create new feelings for a better life!",
    CompetitionText85:"SELF INTRODUCTION:",
    CompetitionText86:"DESIGN CONCEPT ANALYSIS:",
    CompetitionText87:"UI:",
    CompetitionText88:"VIDEO:",
    CompetitionText89:"ADDITIONAL WORK:",
    CompetitionText90:"Design report:",
    CompetitionText91:"Design and display video:",
    CompetitionText92:"Rendering image：",
    CompetitionText93:"Participants please visit our official website:<span style='font-weight:700'>saicdesign.com</span>to register.",
    CompetitionText94:"Fill in your personal information according to the prompts.",
    CompetitionText95:"Upload an ID card photo and one recent photo (jpg, resolution more than 300dpi).",
    CompetitionText96:"Upload projects.",
    CompetitionText97:"Self Introduction:",
    CompetitionText98:"Research Report:",
    CompetitionText99:"Mobility has shaped cities. Bustling ports and dense road networks bring about population and resource accumulation, as well as the rise and prosperity of cities. The geographical environment and cultural aesthetics of a city also influence the form of local transportation tools and the way people interact with vehicles. Cities and cars reshape each other and evolve together. Please focus on user experience, based on understanding and insight into real urban life, and consider how automotive brands can integrate into urban culture? How can automotive culture and urban culture develop together in the future? How can cars integrate into the city and bring innovative experiences to users?",
    CompetitionText100:"Work Requirements:",
    CompetitionText101:"This sub-track advocates for driving brand experience innovation through systemic design thinking. With \"Cities and Cars\" as the theme, it targets areas such as brand image design, product and service innovation design, sustainable design, new consumer experience design, aging-friendly vehicle service design, and social innovation design. Guided by the principles of creating business sustainability, brand sustainability, and innovation sustainability, it aims to provide users with warm and distinctive brand experiences.",
    CompetitionText102:"Submission Materials:",
    CompetitionText103:"1.Personal Introduction : ",
    CompetitionText104:"(One 100-second self-introduction video in MP4 format, and one CV in PDF format)",
    CompetitionText105:"2.Design Content Description",
    CompetitionText106:"3.Problem Process Analysis",
    CompetitionText107:"4.Research Process Analysis",
    CompetitionText108:"5.Brand Positioning Analysis",
    CompetitionText109:"6.Creative Design Presentation",
    CompetitionText110:"Material Format:",
    CompetitionText111:"Apart from personal introduction materials, other materials should preferably be submitted in 16:9 PDF files, JPEG files, or MP4 files (images must have a resolution of at least 300M, video materials should be no longer than 3 minutes).",
    CompetitionText112:"At present, the rapid development of artificial intelligence (AI) has brought new challenges to the traditional 3D digital industry, but at the same time, AI is also a beneficial creative tool for creators. In the field of digital visualization, we encourage innovative thinking as the core, revisiting the model presentation of the winning works in previous competitions. Through the visual presentation of stylized creative scenes, it highlights the charm of the aesthetic design of automobile modeling and highlights the super product power.\n\n" +
      "This competition uses the digital models provided (including the digital models of the previous winners of the competition) as product prototypes. Contestants are encouraged to use various creative tools (traditional 3D software or AI tools) to visually design the static and dynamic images. They can further fine-tune the models or subvert the scenarization definition of the original works, so that the products can show rich and diversified perception and experience. Positive thinking and exploration of the current real life scene or future lifestyle.",
    CompetitionText113:"Requirements for works:",
    CompetitionText114:"The creative theme is not limited, and the content of the work is healthy, positive, positive and has a good mental outlook, without pornography, violence, blood, politics and other negative content. The source of the material elements should be noted (following the principle of original and copyright-free content).",
    CompetitionText115:"Event steps:",
    CompetitionText116:"1. One page of A4 profile information.",
    CompetitionText117:"2, creative intention 100-200 words (optional),",
    CompetitionText118:" mainly explain the content outline, creative concept description, the understanding of modeling design and brand.",
    CompetitionText119:"3. Storyboard design",
    CompetitionText120:" (hand-drawn/computer drawn/material splicing /PPT/ mind map /).",
    CompetitionText121:"4, short version animation sample",
    CompetitionText122:" (not required).",
    CompetitionText123:"The final submission of the competition final",
    CompetitionText124:"1, Personal introduction:",
    CompetitionText125:" 100 seconds or less self-introduction MP4 video *1, resume pdf*1.",
    CompetitionText126:"2. Static renderings of vehicle models",
    CompetitionText127:" (number: more than 9, size: 3840*2160, ratio: 16:9, format png or jpeg).",
    CompetitionText128:"3. Final animation video\n",
    CompetitionText129:"The final entries are submitted in the form of video files, which can be combined with audio-visual language and edited into a film, which can appropriately simplify the scene story, focus on showing the styling charm of the product, and highlight the characteristics of the product. According to the style and tone of the product as the tone direction of the film, it can enhance the sense of identity of the brand image, and there are distinctive creative expression and design propositions in the works.",
    CompetitionText130:"Video format:",
    CompetitionText131:" mp4 format (H264 encoding recommended) 1080p, video size ratio 16:9,The submitted video should be no larger than 800MB and no longer than 5 minutes.",
    CompetitionText132:"Submission specification:",
    CompetitionText133:"Personal Introduction:",
    CompetitionText134:" (Date + author name + Institution + Personal introduction)",
    CompetitionText135:"Creation Intention:",
    CompetitionText136:" (Date + author name + Institution + Creation intention)",
    CompetitionText137:"Storyboard design:",
    CompetitionText138:" (Date + author name + Institution + Storyboard design)",
    CompetitionText139:"Short version animation sample:",
    CompetitionText140:" (Date + author name + University + Short version animation sample)",
    CompetitionText141:"        A digital blogger once said that the characteristic of the Chinese car market is \"The car is only used for riding but not for driving\"，believing that SUVs and boxy MPVs are the destined choice for Chinese consumers. Others firmly believe that the car culture that stimulates human instincts will continue. How to balance the homogenization of \"refrigerator, TV, sofa\" and the sensual beauty of speed? What is the relationship between brand DNA and new technologies, new demands? The 100-year-old MG brand is looking towards the next direction of the era.\n\n" +
      "Reflect on real-life examples from your life, such as the true car needs of older generations, the real attitude towards cars among your peers, etc., to discuss your thoughts on the future automotive lifestyle in China and globally over the next 5 years. Also, propose a suitable development strategy for the MG brand, including but not limited to:\n\n",
    CompetitionText142:"1. Brand Strategy:",
    CompetitionText143:" What value can the MG brand provide to users in the future?...\n\n",
    CompetitionText144:"2. Product Strategy:",
    CompetitionText145:" MG's global product portfolio, core product highlights...\n\n",
    CompetitionText146:"3. Design Strategy:",
    CompetitionText147:" The future style direction of MG design, intelligent cockpit experience strategy...\n\n",
    CompetitionText148:"Work Requirements:",
    CompetitionText149:"Clear viewpoints, comprehensive information, authentic and reliable data, with on-site research results.\n\n" +
      "The framework and visual logic should be clear, readable, and attractive. The application of AI tools for visualizing strategy outcomes is encouraged.",
    CompetitionText150:"Submission Materials:",
    CompetitionText151:"1. Personal Introduction:",
    CompetitionText152:" One 100-second self-introduction video in MP4 format, and one CV in PDF format",
    CompetitionText153:"2. Research Report:",
    CompetitionText154:" PDF format, 16:9 landscape orientation.",
    CompetitionText155:"Starting with the screen, faithful to the experience. Interaction design stems from user needs, emphasizing usability and user experience.\n\n" +
    "Compared to the extraordinary smart driving space imagined, we focus more on the user's \"next\" smart ride experience. It could be a warm and friendly light, a familiar scent, or a moment's anxiety over battery life...\n\n" +
      "Combine sensibility with rationality, integrate imagination with technology, interweave ideals with reality. This competition follows the theme of the Real World, projecting into a foreseeable future imagination. Participants are encouraged to base their work on their understanding of new energy vehicles and the industry, starting from \"functionality,\" using HMI as the point of entry, to craft an \"experiential\" smart cabin.",
    CompetitionText156:"Work Requirements:",
    CompetitionText157:"Participants should read the HMI task book for the sub-track and complete the HMI design plan according to the BRIEF.",
    CompetitionText158:"Submission Materials:",
    CompetitionText159:"1.Personal Introduction:",
    CompetitionText160:" One 100-second self-introduction video in MP4 format, and one CV in PDF format.",
    CompetitionText161:"2.UI design renderings:",
    CompetitionText162:" JPEG format, RGB color, resolution not lower than 300dpi, dashboard 1920*720, center console 1920*1080, at least 3 images.",
    CompetitionText163:"3.Design concept explanation:",
    CompetitionText164:" PDF format, RGB color, single page 16:9 portrait layout. It should include the interaction concept, visual concept, and interpretation of design highlights. Clearly define the driving scenario, demonstrating insight and an effective, feasible solution approach. The size should be comfortable for reading on a mobile device, with appropriate proportions and fonts that are clear and readable.",
    CompetitionText165:"4.Concept video:",
    CompetitionText166:" No longer than 3 minutes, file size no larger than 500MB.",
    CompetitionText167:"5.Additional showcase works:",
    CompetitionText168:" Any extra content that participants wish to upload for display. Personal website/work models/operable prototypes/appendices/motion effects... The format is unrestricted, but accessibility is recommended.",
    CompetitionText169:"In the fiercely competitive arena of automobile brands, cleverly utilizing CMF design can craft a unique hallmark for a brand, deepening the public's impression of it.\n\n" +
      "Facing the real world, CMF design should not be limited to the interior and exterior of the vehicle, but also consider the touchpoints of product marketing. Specific brand colors, vehicle communication colors, brand offline store design, and so on can all become the objects of CMF design.",
    CompetitionText170:"Work Requirements:",
    CompetitionText171:"Please combine the given brand direction and materials, think from the perspective of CMF design to formulate the brand's next-generation CMF design scheme.",
    CompetitionText172:"Submission Materials:",
    CompetitionText173:"1.Personal Introduction:",
    CompetitionText174:" One 100-second self-introduction video in MP4 format, and one CV in PDF format)",
    CompetitionText175:"2. Scheme Report:",
    CompetitionText176:" A complete report in 16:9 format, landscaped. Including theme elucidation, target audience analysis, design trends, storyboard, material board, and interior and exterior effect images.",
    CompetitionText177:"3. Innovative Materials:",
    CompetitionText178:" Incorporating environmental concepts, the work should present a tangible description of innovative eco-friendly material design.",
    CompetitionText179:"4. Presentation Video:",
    CompetitionText180:" A video showcasing the CMF design scheme is required, not exceeding 2 minutes, in MP4 format, 1920*1080, less than 500MB",
    CompetitionText181:"1. Participants please visit our official website: saicdesign.com to register.",
    CompetitionText182:"",
    CompetitionText183:"2. Fill in your personal information according to the prompts.",
    CompetitionText184:"3. Upload an ID card photo and one recent photo",
    CompetitionText185:" (jpg, resolution more than 300dpi).",
    CompetitionText186:"4. Upload projects.\n\n",
    CompetitionText187:"Tips\n",
    CompetitionText188:"The Organizing Committee of SAIC DESIGN CHALLENGE reserves the right of final interpretation of the rules of this competition.",
    // 大赛页面 end

    // 上传页面
    Title: 'SAIC Design Challenge Registration',
    BasicInfo: 'Basic Information',
    BasicDesc: 'We promised your information will only be used for SAIC Design Challenge.',
    Division: 'Division',
    Required: '(required)',
    LastName: 'Last Name',
    Name: 'First Name',
    Gender: 'Gender',
    Male: 'Male',
    Female: 'Female',
    Phone: 'Phone',
    Email: 'Emial',
    EmailInfo: 'tip: Each email address can only be used for one account.',
    Birth: 'Date of birth',
    IDnumber: 'ID number',
    Education: 'Education',
    School: 'School',
    Major: 'Major',
    Graduate: 'Graduation Date',
    Upload: 'Upload Project',
    UploadPhoto: 'Upload a profile photo',
    PictureDesc: '.jpg, resolution more than 300dpi，photo size less than 30M',
    Namework: 'Name of work',
    Description: 'Description',
    UploadDesc: 'Please upload jpq, pdf, videos. No more than 30M for a single file. No more than 500M for a single video',
    DragDesc: 'Please upload jpg, png, pdf, mov. Drag to arrange.',
    Delete: 'Delete',
    ResumeWork: 'Resume and other (optional)',
    NotRequired: '(Not required)',
    Resume: 'Resume',
    SampleReels: 'Sample reels',
    ResumeInfo: 'word, pdf, zip, no more than 30MMb',
    WorksInfo: 'pdf, video, zip.pdf no more than 30Mb, video no more than 500Mb',
    read: 'I have read and agreed to the terms of',
    And: 'and',
    UserRule: '《User Agreement》',
    Privacy: '《Privacy Policy》',
    Submit: 'Submit',
    Select: 'Please select',
    Input: 'Please enter',
    SubmitValid: 'Please make sure you have submitted all the files. This submission will overwrite all the files you uploaded last time.',
    China: 'China',
    Europe: 'Europe',
    Japan: 'Japan',
    Styling: 'Styling Design',
    Design: 'Brand Strategy and Service Design Sub-track',
    HMI: 'Human-machine Interface Design',
    CMF: 'CMF (Color, Material & Finishing) Design',
    Digital: 'Digital Visualization Design',
    Strategy: 'Brand Research & Design Strategy',
    Undergraduate: 'Undergraduate',
    UndergraduateIn: 'Undergraduate (ln progress)',
    Graduates: 'Graduate',
    GraduateIn: 'Graduate (ln Progress)',
    beforeUpload: 'tip: Please fill in your email and division to upload',
    emialValid: 'Email format error',
    idCardValid: '',
    Error: 'Error',
    timeout: 'There are too many visitors currently. Please try refresh the page or try again later',
    videoSize: 'No more than 500M for a single video.',
    fileSize: 'Current type file size cannot exceed 30MB.',
    uploadValid: 'Please upload your project',
    PictureValid: 'Please upload your photo',
    checkWork: 'Exist project have been detect. Do you want to overwrite the origin project information',
    submitSuccess: 'Submit success',
    rule: 'Please tick the 《User Agreement》 and 《Privacy Policy》',
    checkValid: 'Please check required items',
    Downloading:"Downloading",
    zoneValid: 'Division parameter cannot be empty',
    emailValid: 'Email parameter cannot be empty',
    emialFormat: 'Email format error',
    idNumberValid: 'ID number parameter cannot be empty',
    idNumberFormat: 'ID number format error',
    uploadError: 'upload fail',
    uploadTokenError: 'Please try uploading again later',
       // 2025-3-21
       Alibaba:"Alibaba PuHuiTi-Bold",
       deliver:"Click to submit"
}
