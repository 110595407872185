<template>
    <header class="layout-header-pc">
        <div class="image-wrapper">
          <img @click="handleBack" src="@/assets/logo.png" />
        </div>
        <ul class="nav-bar">
            <li
                :class="{active: props.active == index}"
                v-for="(item, index) in props.data"
                :key="item.id"
                @click="handleJump(item.id, index)"
            >{{ $t(item.value) }}</li>
            <li v-if="route.path === '/index'" @click="pageChange">{{ $t('SDCMegagame') }}</li>
        </ul>
        <div class="language-wrapper">
            <button @click="handleJumpEurope">Enter Europe</button>
            <!-- <span :style="{ color: locale === 'zh' ? '#FB3995' : '#000' }" @click="changeLang('zh')">CN</span>
            <span :style="{ color: locale === 'en' ? '#FB3995' : '#000' }" @click="changeLang('en')">EN</span> -->
        </div>
        <!-- <a-tooltip placement="bottom" :getPopupContainer="(triggerNode) => { return triggerNode.parentNode }">
            <template #title>
                <div class="language-wrapper">
                    <p style="padding: 4px 16px; cursor: pointer;" :style="{ opacity: locale === 'zh' ? 1 : .6 }"
                        @click="changeLang('zh')">{{ $t('Chinese') }}</p>
                    <p style="padding: 4px 16px; cursor: pointer;" :style="{ opacity: locale === 'en' ? 1 : .6 }"
                        @click="changeLang('en')">{{ $t('English') }}</p>
                </div>
            </template>
            <span>
                {{ locale === 'zh' ? $t('Chinese') : $t('English') }}
                <img src="@/assets/Frame.png" alt="">
            </span>
            <span>
                {{ locale === 'zh' ? $t('Chinese') : $t('English') }}
                <img src="@/assets/Frame.png" alt="">
            </span>
        </a-tooltip> -->
    </header>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router'
import { reactive, onMounted, onBeforeUnmount, inject} from 'vue'
import { useI18n } from 'vue-i18n'
const { locale } = useI18n()
const route = useRoute()
const router = useRouter()
const indicator = inject('indicator')
const props = defineProps({
  src: {
    type: String,
    default: ''
  },
  data: {
    type: Array,
    default: () => []
  },
  active: {
    type: Number,
    default: 999
  },
  language: {
      type: String,
      default: 'CN'
  }
})

const data = reactive({
    timer: null,
    active: null,
    language: navigator.language.split('-')[1],
})

const pageChange = () => {
    router.push('/eventDetail')
}

const changeLang = (lang) => {
  localStorage.setItem('saic-design-lang', lang)
  locale.value = lang
}

const handleJump = (name) => {
    let vnode = document.querySelector(name)
    window.scrollTo({top: vnode.offsetTop, left: 0, behavior: 'smooth'})
}

const handleBack = () => {
  route.path === '/upload' && router.go(-1)
}

const handleJumpEurope = () => {
  window.open('https://saicdesignchallenge.com', '_blank')
}
</script>

<style lang="less">
@keyframes up {
    0% {
        top: 0;
    }

    100% {
        top: -44px;
    }
}

@keyframes down {
    0% {
        top: -44px;
    }

    100% {
        top: 0;
    }
}

.layout-header-pc.up {
    animation: up ease 0.2s forwards;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
}

.layout-header-pc.down {
    animation: down ease 0.2s forwards;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
}

.layout-header-pc {
    width: 100%;
    height: 48px;
    padding: 0 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: transparent;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    backdrop-filter: blur(20px);
    .image-wrapper {
      width: 156px;
      height: 48px;
      position: absolute;
      top: 0;
      left: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }
    .image-wrapper img {
        width: 156px;
        height: 48px;
        cursor: pointer;
    }

    .nav-bar {
        display: flex;
        height: 28px;
        flex-direction: row;
        align-items: center;
    }

    .nav-bar li {
        color: #000000;
        font-size: 14px;
        padding: 4px 8px;
        margin-right: 40px;
        cursor: pointer;
        position: relative;
        font-weight: bold;
    }

    .nav-bar li:last-child {
        margin: 0;
    }

    .nav-bar li::after {
        content: '';
        width: 100%;
        height: 1px;
        background: #000000;
        position: absolute;
        left: 0;
        bottom: 0;
        display: none;
    }
    .nav-bar li.active::after {
        display: block;
    }
    .nav-bar li:hover::after {
        display: block;
    }

    .language-wrapper {
        height: 100%;
        line-height: 44px;
        position: absolute;
        top: 0;
        right: 24px;
        font-weight: 600;
        cursor: pointer;
        display: flex;
        align-items: center;
    }
    .language-wrapper span {
        font-weight: bold;
        font-size: 14px;
    }
    .language-wrapper span:hover {
        color: #ED89B8 !important;
    }
    .language-wrapper span:active {
        color: #CC196D !important;
    }
    .language-wrapper span:first-child {
        margin-right: 26px;
    }
    .language-wrapper >button {
        border: 0;
        outline: none;
        width: 104px;
        height: 24px;
        border-radius: 52px;
        text-align: center;
        line-height: 20px;
        cursor: pointer;
        color: #ffffff;
        background: #476DEC;
    }
    .language-wrapper >button:hover {
        background: #9192A8;
    }
    .language-wrapper >button:active {
        background: #3258DA;
    }
}
</style>
